import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Placeholder",
        "componentName": "Placeholder"
      }}>{`<Flex>
  {['4:5', '1:1', '4:3', '3:2', '16:9'].map(aspectRatio => {
    return (
      <Box key={aspectRatio} m={3} height="full" width="full">
        <Placeholder aspectRatio={aspectRatio}>
          <Box p={3} height="full" width="full">
            <Caption level={2}>{aspectRatio}</Caption>
          </Box>
        </Placeholder>
      </Box>
    );
  })}
</Flex>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Placeholder" mdxType="Props" />
    <h2 {...{
      "id": "appearance-usage-with-transparent-content",
      "style": {
        "position": "relative"
      }
    }}>{`Appearance (usage with transparent content)`}</h2>
    <p><inlineCode parentName="p">{`default: placeholder`}</inlineCode></p>
    <p>{`When using transparent content, it's better to replace the appearance of the
Placeholder when the content is loaded to plain one. This will prevent seeing
the placeholder trough the transparent content.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  return (
    <Box maxWidth="400px">
      <Placeholder
        aspectRatio="4:3"
        appearance={imageLoaded ? 'plain' : 'placeholder'}
      >
        <img
          onLoad={() => setImageLoaded(true)}
          src="/jesper-aggergaard-unsplash.png"
          alt="Chameleon on a transparent background"
          style={{
            maxWidth: '100%',
            height: 'auto',
            width: 'auto',
          }}
        />
      </Placeholder>
    </Box>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "children",
      "style": {
        "position": "relative"
      }
    }}>{`Children`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Placeholder aspectRatio="16:9">
  <img
    src="http://placekitten.com/1600/900"
    alt="cat"
    style={{
      maxWidth: '100%',
      height: 'auto',
      width: 'auto',
    }}
  />
</Placeholder>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      